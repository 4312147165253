import React from "react";
import Header from "../components/header/Header";
import Slider from "../components/slider/Slider";
import About from "../components/about/About";
import Team from "../components/team/Team";
import Testimonial from "../components/testimonial/Testimonial";
import CallToAction from "../components/calltoactions/CallToAction";
import Footer from "../components/footer/Footer";
import Services from "../components/services/Services";

const Home = () => {
  return (
    <div className="home-three">
      <Header />
      <Slider />
      <About />
      <Services />
      <Team />

      <div className="shane_tm_section" id="why-us">
        <div className="shane_tm_testimonials">
          <div className="container">
            <div className="testimonials_inner">
              <div className="left" data-aos="fade-up" data-aos-duration="7000">
                <div className="shane_tm_title">
                  <span>משרד עו״ד וידבסקי ושות׳</span>
                  <h3>למה לבחור בנו?</h3>
                </div>
              </div>

              <div
                className="right"
                data-aos="fade-up"
                data-aos-duration="7000"
                style={{ textAlign: "right"}}
              >
                <Testimonial />
              </div>
            </div>
          </div>
        </div>
      </div>

      <CallToAction />
      <Footer />
    </div>
  );
};

export default Home;
