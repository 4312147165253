import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faScaleBalanced, faBridge, faPeopleRoof, faBriefcase, faBuilding, faUserInjured ,faBan, faHouseChimney, faCar, faGavel} from "@fortawesome/free-solid-svg-icons";
const servicesList = [
    {
        title: "ליטיגציה ויישוב סכסוכים",
        description: "ייצוג, ליווי וייעוץ משפטי בכל סוגי ההליכים בפני כל ערכאה, לרבות הליכי בוררות וגישור.",
        icon: faScaleBalanced
    },
    {
        title: "נדל\"ן ומיסוי מקרקעין",
        description: "ייצוג, ליווי וייעוץ משפטי בהליכי מכירת או רכישת מקרקעין ודירות, מיסוי מקרקעין ועוד.",
        icon: faHouseChimney
    },
    {
        title: "תעבורה",
        description: "ייצוג, ליווי וייעוץ משפטי בעבירות תעבורה, תאונות דרכים, הפחתת נקודות, קנסות ועוד.",
        icon: faCar
    },
    {
        title: "נזיקין",
        description: "ייצוג, ליווי וייעוץ משפטי בתביעות נזיקין, תאונות דרכים, תאונות עבודה ורשלנות רפואית.",
        icon: faUserInjured
    },
    // {
    //     title: "בוררות וגישור",
    //     description: "ייצוג, ליווי וייעוץ משפטי בהליכי בוררות וגישור.",
    //     icon: faBridge
    // },
    {
        title: "דיני משפחה",
        description: "ייצוג, ליווי וייעוץ משפטי בנושאי גירושין, הסכמי ממון, צוואות, ירושות, ייפוי כוח מתמשך, מסמך הבעת רצון, הנחיות מקדימות למינוי אפוטרופוס, תומך בקבלת החלטות ועוד.",
        icon: faPeopleRoof
    },
    // {
    //     title: "דיני עבודה",
    //     description: "ייצוג, ליווי וייעוץ משפטי בנושאי זכויות עובדים ומעסיקים, חוזי עבודה, תביעות משפטיות ועוד.",
    //     icon: faBriefcase
    // },
    {
        title: "משפט מסחרי ותאגידי",
        description: "ייצוג, ליווי וייעוץ משפטי לעסקים, חברות, לרבות חברות הזנק (סטארט-אפ), עריכת חוזים, הקמת חברות, ליווי שוטף ועוד.",
        icon: faBuilding
    },
    {
        title: "חדלות פירעון \nופשיטות רגל",
        description: "ייצוג, ליווי וייעוץ משפטי בהליכי חדלות פירעון ופשיטות רגל (של חברות ושל יחידים), הסדרי נושים, השגת הפטר מלא או חלקי מחובות ועוד.",
        icon: faBan
    },
    {
        title: "הוצאה לפועל",
        description: "ייצוג, ליווי וייעוץ משפטי בהליכי הוצאה לפועל, גביית חובות, הוצאה לפועל של פסקי דין, בקשה לביצוע שטרות (שיק), הליכי מימוש נכסים ועוד.",
        icon: faGavel
    },
]

const Services = () => {
    return (
        <div className="shane_tm_section" id="services">
            <div className="shane_tm_news">
                <div className="container">
                    <div className="shane_tm_title">
                        <span>שירותי המשרד</span>
                        <h3>שירותים משפטיים ותחומי התמחות</h3>
                    </div>
                    <div className="news_list">
                        <ul>
                            {servicesList.map((item, index) => (
                                <li key={index} data-aos="fade-right" data-aos-duration="1200">
                                    <div className="list_inner">
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <FontAwesomeIcon icon={item.icon}  style={{ width: '48px', height: '48px' }}  />
                                        </div>

                                        <div className="details">
                                            <h3 className="title" style={{ whiteSpace: 'pre-line' }}>
                                                {item.title}
                                            </h3>
                                            <p className="date">
                                                {item.description}
                                            </p>
                                        </div>
                                    </div>
                                </li>)
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
