import React from "react";
import { useForm } from "react-hook-form";

const Contact = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = async (data, e) => {
    try {
      const response = await fetch('send_mail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(data)
      });
      onSubmit();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <form className="contact_form" onSubmit={handleSubmit(submit)}>
        <div className="first">
          <ul>
            <li>
              <input
                {...register("name", { required: true })}
                type="text"
                placeholder="שם"
              />
              {errors.name && errors.name.type === "required" && (
                <span>אנא הזן שם</span>
              )}
            </li>
            {/* End first name field */}

            <li>
              <input
                {...register(
                  "email",
                  {
                    required: "Email is Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  },
                  { required: true }
                )}
                type="email"
                placeholder="דוא״ל"
              />
              {errors.email && <span role="alert">{errors.email.message}</span>}
            </li>
            {/* End email name field */}

            <li>
              <textarea
                {...register("subject", { required: true })}
                placeholder="הודעה"
              ></textarea>
              {errors.subject && <span>אנא הזן הודעה</span>}
            </li>
            {/* End subject  field */}
          </ul>
        </div>

        <div className="tokyo_tm_button">
          <button type="submit" className="white-fill-bg fill-black">
            שלח
          </button>
        </div>
        {/* End tokyo_tm_button */}
      </form>
      {/* End contact */}
    </>
  );
};

export default Contact;
