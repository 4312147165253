import React, { useState } from "react";
import Modal from "react-modal";
import {ContactModal} from "./ContactModal";

Modal.setAppElement("#root");

const CallToAction = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="shane_tm_section" id="contact">
      <div
        className="shane_tm_talk bg_image_props"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "img/footer-bg.jpg"
          })`,
        }}
      >
        {/*<div className="shape">*/}
        {/*  <img className="svg" src="/img/svg/paper.svg" alt="partners brand" />*/}
        {/*</div>*/}
        {/* End shape */}

        <div className="background" id="talk">
          <a className="player"></a>
          <div className="overlay"></div>
        </div>
        {/* End background */}

        <div className="talk_inner">
          <div className="text" data-aos="fade-up" data-aos-duration="1200">
            <h3>אנחנו פה בשבילך!</h3>
          </div>

          <div
            className="button"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100"
          >
            <button className="white-fill-bg" onClick={() => setIsOpen(!isOpen)}>
              צור קשר
            </button>
          </div>
        </div>
        {/* End talk_inner */}
      </div>
      <ContactModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default CallToAction;
