import React, {useState} from "react";
import {ContactModal} from "../calltoactions/ContactModal";

const About = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="shane_tm_section" id="about">
        <div className="shane_tm_about">
          <div className="container">
            <div className="about_inner">
              <div className="left">
                <div
                  className="image"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <img src="/img/about.jpg" alt="placeholder" />

                  <div
                    className="main"
                    style={{ backgroundImage: "url(img/about.jpg)" }}
                  ></div>
                </div>
              </div>
              <div className="right">
                <div
                  className="shane_tm_title"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <span>אודות</span>
                  <h3>משרד עורכי דין וידבסקי ושות'</h3>
                </div>
                <div
                  className="text"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <p>
                    הוא משרד עורכי דין בעל ניסיון עשיר ומוניטין מבוסס בתחום המשפטי. אנו מספקים ייעוץ משפטי ושירותי ייצוג מקצועיים ללקוחות פרטיים, עסקים וחברות, בכל תחומי המשפט האזרחי, המסחרי והפלילי.                  </p>
                </div>
                <div
                  className="shane_tm_button"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  onClick={() => setIsOpen(!isOpen)}
                >
                   <a> צור קשר</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </>
  );
};

export default About;
