import Modal from "react-modal";
import Contact from "../Contact";
import Social from "../Social";
import React from "react";

export const ContactModal = ({isOpen, setIsOpen}) => (
    <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(!isOpen)}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
    >
        <div className="shane_tm_mobalbox_contact">
            <button className="close-modal" onClick={() => setIsOpen(!isOpen)}>
                <img src="/img/cancel.svg" alt="close icon" />
            </button>
            {/* End close icon */}
            <div className="box_inner">
                <div className="description_wrap scrollable">
                    {/* Start modal content */}
                    <div className="title">
                        <h3>צור קשר</h3>
                    </div>
                    {/* End title */}

                    <div className="wrapper">
                        <div className="left">
                            <div className="fields">
                                <Contact onSubmit={() => setIsOpen(false)}/>
                            </div>
                        </div>
                        {/* End left */}
                        <div className="right">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3411.19865026434!2d34.78868717629593!3d31.242923674342236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x150266140599f7ff%3A0xcf9b8e60f3fe96d1!2sHerzl%20St%20118%2C%20Beersheba!5e0!3m2!1sen!2sil!4v1723396296742!5m2!1sen!2sil"
                                width="500" height="360" allowFullScreen="" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        {/* End right */}
                    </div>
                    {/* End wrapper */}

                    <div className="short_info">
                        <ul>
                            <li>
                                <div className="list_inner">
                                    <img
                                        className="svg"
                                        src="img/location.svg"
                                        alt="location"
                                    />
                                    <p>רח' הרצל 118, באר שבע</p>
                                </div>
                            </li>
                            {/* End silgle address */}

                            <li>
                                <div className="list_inner">
                                    <img
                                        className="svg"
                                        src="img/phone.svg"
                                        alt="phone"
                                    />
                                    <p>
                                        <a href="tel:+972086270055">08-6270055</a><br />
                                        <a href="tel:+9720528264274">052-8264274</a>
                                    </p>
                                </div>
                            </li>
                            {/* End silgle address */}

                            <li>
                                <div className="list_inner">
                                    <img
                                        className="svg"
                                        src="img/share.svg"
                                        alt="share"
                                    />
                                    <Social />
                                </div>
                            </li>
                            {/* End silgle address */}
                        </ul>
                    </div>
                    {/* End modal conetent */}
                </div>
            </div>
            {/* End box inner */}
        </div>
        {/* End modalbox news */}
    </Modal>
)