import React from "react";

const Team = () => {
    return (
        <>
            <div className="shane_tm_section" id="team">
                <div className="shane_tm_about">
                    <div className="container">
                        <div className="about_inner">
                            <div className="left">
                                <div
                                    className="shane_tm_title"
                                    data-aos="fade-up"
                                    data-aos-duration="1200"
                                >
                                    <span>צוות</span>
                                </div>
                                <div
                                    className="text"
                                    data-aos="fade-up"
                                    data-aos-duration="1200"
                                >
                                    <p>
                                        <span>עו"ד כפיר וידבסקי: </span>
                                        לאחר מספר שנים בפירמות עורכי דין מהגדולות במשק הישראלי, הקים עורך דין כפיר וידבסקי את משרד עורכי דין וידבסקי ושות'.
                                        עו"ד וידבסקי מתמחה בכל תחומי המשפט האזרחי – מסחרי, לרבות ייעוץ, ליווי וייצוג בפני כלל הערכאות, דיני משפחה, נזיקין, פשיטות רגל, הוצאה לפועל, נדל"ן ועוד.
                                        עו"ד וידבסקי בוגר האוניברסיטה העברית במשפטים וכלכלה (מסלול משולב), הוסמך על ידי האפוטרופוס הכללי לערוך ייפוי כוח מתמשך והוא בוגר קורס גישור מטעם לשכת עורכי הדין.

                                    </p>
                                </div>
                            </div>
                            {/* End right */}
                            <div className="left">
                                <div
                                    className="image"
                                    data-aos="fade-up"
                                    data-aos-duration="1200"
                                >
                                    <img src="/img/team.jpg" alt="placeholder" />

                                    <div
                                        className="main"
                                        style={{ backgroundImage: "url(img/team.jpg)" }}
                                    ></div>
                                </div>
                                {/* End image */}
                            </div>
                            {/* End left */}

                        </div>
                    </div>
                    {/* End container */}
                </div>
            </div>
        </>
    );
};

export default Team;
