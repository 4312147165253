import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const items = [
  {
    title: "ניסיון ומקצועיות",
    description: "עם שנות ניסיון רבות בתחומי המשפט השונים ועיסוק בתיקים מהקטנים ביותר ועד הליכים בשווי מיליוני שקלים, אנו מבטיחים טיפול מקצועי , פרטני ומעמיק בכל מקרה ומקרה.",
  },
  {
    title: "יחס אישי",
    description: "אנו מקפידים על מתן שירות אישי ואכפתי לכל לקוח, תוך הבנת צרכיו ומציאת הפתרון המתאים ביותר עבורו.",
  },
  {
    title: "הצלחה מוכחת",
    description: "ברזומה שלנו מגוון הצלחות משפטיות מרשימות, אשר מעידות על יכולתנו להשיג את התוצאות הטובות ביותר עבור לקוחותינו.",
  }
]

export default function Testimonial() {
  const settings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <ul>
      <Slider {...settings}>
        {items.map((item, i) => (
            <li className="item" key={i}>
              <div className="texts">
                <p>
                  <h3>{item.title}</h3>
                  {item.description}
                </p>
              </div>
            </li>
        ))}
      </Slider>
    </ul>
  );
}
