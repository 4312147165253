import React from "react";

const Footer = () => {
  return (
    <>
      <div className="shane_tm_section">
        <div className="shane_tm_copyright">
          <div className="container">
            <div className="inner">
              <p>
                כל הזכויות שמורות. משרד עו״ד וידבסקי ושות׳ &copy;
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
