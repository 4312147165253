import React, { useEffect } from "react";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import {Route, Routes} from "react-router-dom";
import Home from "./views/Home";

const App = () => {
  useEffect(() => {
    AOS.init();
    document.body.classList.add("loaded");
  }, []);
  return (
    <div className="shane_tm_all_wrap">
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<Home />} />
      </Routes>
    </div>
  );
};

export default App;
