import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faLinkedin, faSquareFacebook, faSquareTwitter } from "@fortawesome/free-brands-svg-icons";
const SocialShare = [
  {
    icon: faSquareFacebook,
    link: "https://www.facebook.com/",
  },
  { icon: faSquareTwitter, link: "https://twitter.com/" },
  {
    icon: faLinkedin,
    link: "https://www.linkedin.com/",
  },
];
const Social = () => {
  return (
    <div className="share">
      <span>Share:</span>
      <ul className="social">
        {SocialShare.map((val, i) => (
          <li key={i}>
            <a href={`${val.link}`} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={val.icon} style={{ width: '24px', height: '24px' }}/>
            </a>
          </li>
        ))}
      </ul>

      {/* END social */}
    </div>
  );
};

export default Social;
