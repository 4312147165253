import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <div className="shane_tm_topbar">
        <div className={navbar ? "topbar_inner opened" : "topbar_inner"}>
          <div className="menu">
            <Scrollspy
                className="anchor_nav"
                items={["home", "about", "portfolio", "news", "contact"]}
                currentClassName="current"
                offset={-200}
            >
              <li>
                <a href="#home">בית</a>
              </li>
              <li>
                <a href="#about">אודות</a>
              </li>
              <li>
                <a href="#team">צוות</a>
              </li>
              <li>
                <a href="#services">שירותים</a>
              </li>
              <li>
                <a href="#why-us">למה אנחנו?</a>
              </li>
              <li>
                <a href="#contact">צור קשר</a>
              </li>
            </Scrollspy>
          </div>
          <div className="logo">
            <NavLink to="/">
              <img src="/img/logo.jpg" alt="partners brand"/>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="shane_tm_mobile_menu">
        <div className="topbar_inner">
        <div className="container bigger">
            <div className="topbar_in">
              {/*<div className="logo">*/}
              {/*  <NavLink to="/">*/}
              {/*    <img src="/img/logo/new/dark.png" alt="partners brand" />*/}
              {/*  </NavLink>*/}
              {/*</div>*/}
              <div className="my_trigger" onClick={handleClick}>
                <div
                  className={
                    click
                      ? "hamburger hamburger--collapse-r is-active"
                      : "hamburger"
                  }
                >
                  <div className="hamburger-box">
                    <div className="hamburger-inner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={click ? "dropdown active" : "dropdown"}>
          <div className="container">
            <span className="close_menu" onClick={handleClick}>
              close
            </span>
            <div className="dropdown_inner">
              <ul className="anchor_nav">
                <li className="current">
                  <a href="#home" onClick={handleClick}>
                    בית
                  </a>
                </li>
                <li>
                  <a href="#about" onClick={handleClick}>
                    אודות
                  </a>
                </li>
                <li>
                  <a href="#team" onClick={handleClick}>
                    צוות
                  </a>
                </li>
                <li>
                  <a href="#services" onClick={handleClick}>
                    שירותים
                  </a>
                </li>
                <li>
                  <a href="#why-us" onClick={handleClick}>
                    למה אנחנו?
                  </a>
                </li>
                <li>
                  <a href="#contact" onClick={handleClick}>
                    צור קשר
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
